import { Helmet } from 'react-helmet';

import { useState, useCallback, useEffect } from 'react';
import { Container, Card, CardHeader, CardBody, CardTitle, CardFooter, Badge, Row, Col } from "reactstrap";

import { v4 as uuidv4 } from 'uuid';

import sections from './sections'

const HomePage = ({LOGO}) => {

    const [allSections, setAllSections] = useState([]);

    const onSidebarClick = useCallback((id) => {
        const el = document.getElementById(id);
        if(el) el.scrollIntoView()
    }, [])

    // assign a unique id to each section
    useEffect(() => {
        const all = [];
        sections.forEach(s => {
            all.push({ ...s, id: uuidv4() })
        });
        setAllSections(all)
    }, [])

    return (
        <>

			<Helmet>
				<title>IntuiDesk Documentation</title>
				<meta name="description" content="IntuiDesk" />
			</Helmet>


			{/* <Container>

               <Card className='mb-0'>
                    <CardBody>
                        <h1 className='display-1 text-dark'>IntuiDesk Documentation</h1>
                        <p className='mb-0'>Use this page to refer to documenation on how to use IntuiDesk and understand how it thinks.</p>
                    </CardBody>
               </Card>

            </Container> */}

        <Container>

            <Row>
                <div className="col-auto d-none d-md-block" style={{width: 200}}>
                    <div className='sticky-top py-4'>
                        <div style={{overflow: 'auto', maxHeight: 'calc(100vh - 30px)'}}>

                            <h2>All Topics</h2>
                            <ul className='pl-0'>
                                {allSections.map((section) => (
                                    <li 
                                        onClick={() => onSidebarClick(section.id)} 
                                        className='list-style-none cursor-pointer' 
                                        key={section.id}
                                    >
                                        {section.title}
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </div>
                </div>

                <Col>
                <h1 className='display-3 text-uppercase mt-3'>IntuiDesk Docs</h1>
                    {allSections.map((section, i) => (
                        <div key={i} id={section.id}>
                            <Card className='mb-0'>

                                <CardBody>
                                    <CardTitle className='display-4 mt-0 mb-0 text-dark'>
                                        {section.title}
                                    </CardTitle>
                                </CardBody>

                                {section.description && section.description.length ? (
                                    <CardFooter>
                                        {section.description.map((description, ii) => (
                                            ii === 0 ? (
                                                <p key={`${i}-${ii}`} className='text-sm mb-0'>
                                                    <i className="fas fa-info-circle text-info" /> 
                                                    {' '}
                                                    {description}
                                                </p>
                                            ) : (
                                                <p key={`${i}-${ii}`} className='text-sm mb-0 mt-3'>
                                                    {description}
                                                </p>
                                            )
                                        ))}

                                    </CardFooter>
                                ) : ''}
                            
                                {section.items && section.items.length ? (
                                    <CardFooter>
                                        {section.items.map((item, ii) => (
                                            <div key={`${i}-${ii}`}>

                                                <p className='mb-0 font-weight-bold text-dark'>
                                                    {item.title}
                                                </p>

                                                {item.list_items && item.list_items.length ? (
                                                    <ul>
                                                        {item.list_items.map((list_item, iii) => (
                                                            <li key={`${i}-${ii}-${iii}`}>
                                                                {list_item}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : ''}

                                            </div>
                                        ))}
                                    </CardFooter>
                                ) : ''}

                            </Card>
                        </div>
                    ))}
                </Col>

            </Row>

            </Container>

           


		</>

    )
}

export default HomePage
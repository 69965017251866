const sections = [
    {
        title: 'Support Tickets',
        description: ['Support tickets are automatically created from incoming calls, text messages, or emails. The goal of these tickets is to unify incoming communication across channels so once one is created on a matter it will stay open until a user marks it as closed. This ticket will grow to include all incoming and outgoing communication for easy reference by a user. Refer to the below information for how IntuiDesk will assign these tickets.'],
        items: [
            { 
                title: <span>Text Message Assignment Order <small>(Assignment will be the first user(s) to match one of the conditions below)</small></span>,
                list_items: [
                    `Users assigned by a matter's "Route Communication To" property.`,
                    `Users already assigned to the text message thread.`,
                    `A user selected at random found within the division that has the privilege for "Random Communication Assignment".`,
                    `A user selected at random found within the division.`,
                ]
            },
            {
                title: `Phone Call Assignment Order`,
                list_items: [
                    `Users assigned by a matter's "Route Communication To" property.`,
                    `A user selected at random found within the division that has the privilege for "Random Communication Assignment".`,
                    `A user selected at random found within the division.`,
                ]
            },
            {
                title: `Email Assignment Order`,
                list_items: [
                    `The user(s) the email was addressed to.`,
                ]
            }
        ]
    },
    {
        title: 'Emails',
        description: [`Emails can be sent multiple different ways, the first is via a linked email where the email is sent directly from a users outbox inside of there email client and via Sendgrid and an email sender. We recommend using Sendgrid for transactional and marketing emails as to not create any bad reputation around your main sending domain.`],
        items: [
            { 
                title: 'Linked Email Accounts',
                list_items: [
                    `For a user to send an email either manually or programmatically from their designated email address they must 1) have the designated email address assigned under their division overrides (Click on staffing in the admin dashboard and then the blue "Gear" icon) and 2) they must have linked this email address by going to their account settings and then "Calendar / Email".`,
                    `Note that if a user receives an error either on their calendar or when trying to send an email they should go back to this page and re-login by clicking the button next to their associated account.`
                ]
            },
            {
                title: `Sendgrid Emails`,
                list_items: [
                    `Sendgrid emails use Sendgrid as a service to send emails NOT linked to a specific user's account. They must be associated with an email sender and have a reply to address assigned for any emails that come back as a result of them being sent out. We recommend you use a domain on Sendgrid NOT linked with your main domain. For instance if you main domain is test.com we recommend buying a domain such as mytest.com and using this domain to send out Sendgrid emails.`,
                ]
            },
            {
                title: `Email Senders`,
                list_items: [
                    `Email senders are created on the admin dashboard and be any "person" you wish them to be as long as they are valid emails on your main Sendgrid account. Under your division settings you should specifiy a default email sender as this will be the email that sends our programmatic IntuiDesk Notices, send default emails to your clients etc.`,
                ]
            },
            {
                title: 'Api Key Connection',
                list_items: [
                    `Note that for both linked emails and Sendgrid emails you will need to add a Nylas and Sendgrid set of API keys under your company settings respectively.`
                ]
            }
        ]
    },
    {
        title: 'Phone Numbers',
        description: [`Phone numbers are bought directly on your Twilio dashboard and can then be added under the "Communication" tab in the admin dashboard. These numbers will be capable of sending and receiving text messages and making and receiving phone calls.`],
        items: [
            { 
                title: 'Contact Assignment',
                list_items: [
                    `The first time a contact either receives an inbound call or text or an outbound text/call is made to them a number will be assigned to their profile for the correlating division. This phone number will automatically be used for all outgoing calls and text messages in the future to keep things simple for your clients. This match first takes place by trying to match a phone number you have added to the zip code of the clients phone. If no match is found a random number will be assigned from your list. Should you ever need to change this you may open the contacts profile on the app dashboard, and then click on the "Divisions" tab.`
                ]
            },
            { 
                title: 'Syncing Phone Numbers',
                list_items: [
                    `After you have added a phone number you can click into it to see a blue "Run Twilio Sync" button. Once you press this the routing of your twilio phone number will be pointed over to IntuiDesk and you can begin sending and receiving phones calls. Until you do this any phone numbers that have been added will not work correctly and will display in red that they are not synced.`
                ]
            },
        ]
    },
]

export default sections;

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Docs from 'views/docs/layout';

const App = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/"   render={props => <Docs    {...props} />  } />
        </Switch>
    </BrowserRouter>
)

export default App